<!-- 拍卖申请-确认方案 -->
<template>
  <div class="confirmPlan">
    <div class="applicationNo">申请编号：{{ this.applicationNumber }}</div>
    <div class="title">确认方案</div>

    <div class="listBox" v-loading="loading">
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="tit">
            <div class="wz">
              {{ item.auctionTitle }}
            </div>
            <div class="deposit">
              <span>策拍人: </span>
              <span>{{ item.person }}</span>
            </div>
          </div>
          <div class="contentDetail">
            <div class="timeLine">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in item.timeLineData"
                  :key="index"
                  :timestamp="activity.timestamp"
                  placement="top"
                  :color="activity.color"
                  :icon="activity.icon"
                >
                  {{ activity.content }}
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="timeLine" style="margin-left: 26px">
              <div class="projectConsultation">
                <span>参拍报名保证金:</span>
                <span>{{ formatMoneyString(item.deposit) }}元</span>
              </div>
              <div class="projectConsultation">
                <span>起拍价:</span>
                <span v-if="item.isPreQuotation == 'Y'">开拍前公布</span>
                <span v-else>
                  <span v-if="item.quoting == 1"
                    >{{ formatMoneyString(item.startingPrice) }}元 /
                    {{ item.quotationUnit }}</span
                  >
                  <span v-else
                    >{{ formatMoneyString(item.startingPrice) }}元</span
                  >
                </span>
              </div>
              <div class="projectConsultation">
                <span>最小加价幅:</span>
                <span v-if="item.isPreQuotation == 'Y'">开拍前公布</span>
                <span v-else>
                  <span v-if="item.quoting == 1"
                    >{{ formatMoneyString(item.minimumMarkup) }}元 /
                    {{ item.quotationUnit }}</span
                  >
                  <span v-else
                    >{{ formatMoneyString(item.minimumMarkup) }}元</span
                  >
                </span>
              </div>
              <div class="projectConsultation">
                <span>参拍成交服务费率:</span>
                <span>{{ item.auctionServiceFeeRate }}%</span>
              </div>
              <div class="projectConsultation">
                <span>拍卖保证金:</span>
                <span>￥{{ formatMoneyString(item.bidBond) }}元</span>
              </div>
              <div class="projectConsultation">
                <span>拍卖服务费费率:</span>
                <span v-if="item.payType == '1'"
                  >￥{{ formatMoneyString(item.biddingServiceFeeRate) }}元</span
                >
                <span v-else>{{ item.biddingServiceFeeRate }}%</span>
              </div>
            </div>
            <!-- <div class="auctionDetail">
              <div class="projectConsultation">
                <span>起拍价</span>
                <span v-if="item.isPreQuotation == 'Y'">开拍前公布</span>
                <span v-else
                  >￥{{ formatMoneyString(item.startingPrice) }}元</span
                >
              </div>
              <div class="projectConsultation">
                <span>保证金</span>
                <span>{{ formatMoneyString(item.deposit) }}元</span>
              </div>
            </div> -->
          </div>

          <div class="btnBox">
            <el-button
              size="small"
              type="success"
              plain
              @click="toNoticeDetail(item.id)"
            >
              预览公告详情
            </el-button>
            <!-- <div class="detail pub">拍卖详情</div> -->
          </div>
        </div>
      </div>

      <div class="btnStyle">
        <el-button type="success" plain @click="onWithdraw()"
          >撤回申请</el-button
        >
        <el-button type="success" plain @click="toUp()">修改要求</el-button>
        <el-button type="success" @click="onConfirm()">确认方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  confirmAuctionList,
  confirmUpdateStatus,
  withdrawUpdateStatus,
} from "@/api/workSpace";
import moment from "moment";
export default {
  data() {
    return {
      id: "", //修改要求使用
      loading: false,
      listData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      status: 0,
      baseUrl: "/api",
      applicationNumber: "",
    };
  },
  mounted() {
    this.applicationNumber = this.$route.query.auctionCode;
    this.getList();
  },
  methods: {
    // 分页
    handleCurrentChange() {},

    getList() {
      this.loading = true;
      confirmAuctionList({ applicationNumber: this.applicationNumber }).then(
        (res) => {
          if (res.code == 200) {
            let data = res.data.map((item) => {
              item.url =
                this.baseUrl +
                "/file/displayResource?objectName=" +
                item.objectNames[0];
              item.person = item.operator + " " + item.operatorNumber;
              item.timeLineData = [];
              item.timeLineData.push({
                content: "拍卖信息发布",
                timestamp: moment(new Date(item.releaseTime)).format(
                  "YYYY-MM-DD HH:mm"
                ),
              });
              if (item.isPreview == "Y") {
                item.timeLineData.push({
                  content: "预展览开始",
                  timestamp: moment(new Date(item.previewStartTime)).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                });
                item.timeLineData.push({
                  content: "预展览结束",
                  timestamp: moment(new Date(item.previewEndTime)).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                });
              }

              item.timeLineData.push({
                content: "拍卖开始",
                timestamp: moment(
                  new Date(item.dateDay + " " + item.auctionStartTime)
                ).format("YYYY-MM-DD HH:mm"),
              });
              item.timeLineData.push({
                content: "拍卖合同签署截止日期",
                timestamp: moment(new Date(item.contractLatestTime)).format(
                  "YYYY-MM-DD HH:mm"
                ),
              });
              return item;
            });
            let nowDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
            // 给时间线添加color
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].timeLineData.length; j++) {
                if (data[i].timeLineData[j].timestamp < nowDate) {
                  // 如果当前下标的日期小于当前时间，添加color显示为绿色
                  data[i].timeLineData[j].color = "#0DAC65";
                } else {
                  // 如果当前下标日期大于当前日期把当前日期push到当前下标
                  data[i].timeLineData.splice(j, 0, {
                    content: "当前日期",
                    timestamp: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                    icon: "el-icon-pie-chart",
                    color: "#0DAC65",
                  });
                  break;
                }
              }
            }
            this.listData = data;
            this.loading = false;
          }
        }
      );
    },

    // 公告详情
    toNoticeDetail(data) {
      this.$router.push({
        path: "/Notice/announcementDetails",
        query: {
          id: data,
        },
      });
    },

    // 撤回申请
    onWithdraw() {
      this.$confirm(
        "您确定要撤回申请吗？撤回申请后信息不可恢复，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          status: 7,
          applicationNumber: this.applicationNumber,
        };
        withdrawUpdateStatus(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$router.push({
              path: "/workSpace",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },

    // 修改要求
    toUp() {
      this.$emit("updateActive", 2);
      // this.$EventBus.$emit("applicationNumber", this.applicationNumber);
    },

    // 确认方案
    onConfirm() {
      // let params = {
      //   status: 3,
      //   applicationNumber: this.applicationNumber,
      // };
      confirmUpdateStatus(this.listData).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.$router.push({
            path: "/workSpace",
            query: {
              activeName: "fourth",
            },
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-timeline-item {
  padding-bottom: 5px;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
/deep/.el-timeline-item__wrapper {
  display: flex;
}

/deep/.el-timeline-item__timestamp.is-top {
  margin-right: 10px;
}
/deep/.el-timeline-item__timestamp {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
/deep/.el-timeline-item__content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.confirmPlan {
  .applicationNo {
    font-size: 16px;
  }
  .title {
    font-weight: 500;
    font-size: 22px;
    color: #000000d9;
    text-align: center;
  }
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid#e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        .tit {
          width: 100%;
          display: flex;
          .public {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          .wz {
            width: calc(100% - 200px);
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          height: 130px;
          margin-top: 18.5px;
          // background-color: #ccc;
          display: flex;

          .timeLine {
            width: 41%;
            height: 100%;
            overflow: auto;
            padding: 10px 0 10px 16px;
            background: rgba(216, 216, 216, 0.12);
            box-sizing: border-box;
          }
          .projectConsultation {
            line-height: 26px;
            span {
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                color: #00000066;
                margin-right: 12px;
              }
              &:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #000000d9;
              }
            }
          }
          .auctionDetail {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: 26px;
            .actionCode,
            .actionTime,
            .deposit,
            .projectConsultation {
              margin-right: 40px;
              span {
                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #00000066;
                  margin-right: 12px;
                }
                &:last-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000d9;
                }
              }
            }
          }
        }

        .btnBox {
          // width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          .pub {
            width: 100px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-left: 20px;
          }
          .hall {
            background: #0dac65;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .detail {
            background: #ffffff;
            border: 1px solid #0dac65;
            color: #0dac65;
          }
          .cancel {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            color: #666666;
          }
        }
      }
    }

    .btnStyle {
      display: flex;
      justify-content: flex-end;
      margin-top: 50px;
      padding-bottom: 20px;
      box-sizing: border-box;
      .modify {
        width: 120px;
        height: 40px;
        border: 1px solid #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #0dac65;
        text-align: center;
        line-height: 40px;
        box-sizing: border-box;
        margin-left: 20px;
        cursor: pointer;
      }
      .confirm {
        width: 120px;
        height: 40px;
        background: #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
