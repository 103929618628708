<!-- 拍卖申请-基础信息 -->
<template>
  <div class="baseInfoBox">
    <div class="title">基础信息</div>
    <div class="formBox">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="165px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="出卖人主体企业" prop="applyForBusiness">
          <el-input v-model="ruleForm.applyForBusiness" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业统一社会信用代码" prop="manufactoryCode">
          <el-input v-model="ruleForm.manufactoryCode" disabled></el-input>
        </el-form-item>
        <el-form-item label="代申请人" prop="realName">
          <el-input v-model="ruleForm.realName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="userName">
          <el-input v-model="ruleForm.userName"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-col :span="17">
            <el-input v-model="ruleForm.code"></el-input>
          </el-col>
          <el-col :offset="1" :span="6" style="text-align:right">
            <el-button type="text" :disabled="countdown > 0" @click="getYzm">
              {{ countdown > 0 ? `重新获取(${countdown}s)` : "点击获取验证码" }}
            </el-button>
          </el-col>
        </el-form-item>
        <div class="btnStyle">
          <el-button type="success" plain @click="onCancel">取消发布</el-button>
          <el-button type="success" @click="onSubmit">下一步</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getSmsCode } from "@/api/index.js";
import { getBaseInfo, validateSms } from "@/api/workSpace.js";
export default {
  data() {
    return {
      ruleForm: {
        userName: "",
      },
      countdown: 0,
      rules: {
        applyForBusiness: [
        { required: true, message: "请输入出卖人主体企业", trigger: "change" },
        ],
        manufactoryCode: [
        { required: true, message: "请输入企业统一社会信用代码", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入代申请人", trigger: "change" },
        ],
        userName: [
          { required: true, message: "请输入联系电话", trigger: "chenge" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "change",
          },
        ],
        code: [
          { required: true, message: "请输入短信验证码", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getYzm() {
      this.countdown = 60;
      let param = {
        mobile: this.ruleForm.userName,
      };
      console.log(param);
      getSmsCode(param).then(({ code, msg }) => {
        if (code !== 200) {
          this.$message.error(msg);
        }
      });
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    // 下一步
    onSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            phoneNumber: this.ruleForm.userName,
            code: this.ruleForm.code,
          };
          validateSms(params).then((res) => {
            if (res.code == 200) {
              this.$emit("updateActive", 1);
              this.$EventBus.$emit("baseInfoData", this.ruleForm);
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          this.$message({
            message: "请填写完整此表单！",
            type: "warning",
          });
        }
      });
    },
    onCancel() {
      this.$router.go(-1);
    },

    // 获取公司信息
    getInfo() {
      getBaseInfo().then((res) => {
        this.ruleForm = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--mini, .el-button--small{
  font-size: 14px;
}
/deep/.el-form-item__label{
  color: rgba(0, 0, 0, 0.85) !important;
}
/deep/.el-input__inner{
  color: rgba(0, 0, 0, 0.85) !important;
}
/deep/.el-input.is-disabled .el-input__inner{
  color: rgba(0, 0, 0, 0.25) !important;
}
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
.baseInfoBox {
  .title {
    font-weight: 500;
    font-size: 22px;
    color: #000000d9;
    text-align: center;
  }
  .formBox {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .el-form {
      width: 50%;
    }
    .btnStyle {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px !important;
      margin-bottom: 20px;
      .btn {
        width: 120px;
        height: 40px;
        background: #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin-left: 20px;
      }
      .cancel {
        width: 120px;
        height: 40px;
        border: 1px solid #0dac65;
        font-weight: 400;
        font-size: 16px;
        color: #0dac65;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
  }
}
</style>
