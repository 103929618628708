<!-- 拍卖申请-付款发布 -->
<template>
  <div class="paymentRelease">
    <div class="applicationNo">申请编号：{{ this.applicationNumber }}</div>
    <div class="title">付款发布</div>

    <div class="listBox" v-loading="loading">
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="img">
          <img :src="item.url" alt="" />
        </div>

        <div class="listContent">
          <div class="tit">
            <div class="wz">
              {{ item.auctionTitle }}
            </div>
            <div class="deposit">
              <span>策拍人: </span>
              <span>{{ item.person }}</span>
            </div>
          </div>
          <div class="contentDetail">
            <div class="timeLine">
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in item.timeLineData"
                  :key="index"
                  :timestamp="activity.timestamp"
                  placement="top"
                  :color="activity.color"
                  :icon="activity.icon"
                >
                  {{ activity.content }}
                </el-timeline-item>
              </el-timeline>
            </div>
            <div class="timeLine" style="margin-left:26px">
              <div class="projectConsultation">
                <span>参拍报名保证金:</span>
                <span>{{ formatMoneyString(item.deposit) }}元</span>
              </div>
              <div class="projectConsultation">
                <span>起拍价:</span>
                <span v-if="item.isPreQuotation == 'Y'">开拍前公布</span>
                <span v-else>
                  <span v-if="item.quoting == 1"
                    >{{ formatMoneyString(item.startingPrice) }}元 /
                    {{ item.quotationUnit }}</span
                  >
                  <span v-else
                    >{{ formatMoneyString(item.startingPrice) }}元</span
                  >
                </span>
              </div>
              <div class="projectConsultation">
                <span>最小加价幅:</span>
                <span v-if="item.isPreQuotation == 'Y'">开拍前公布</span>
                <span v-else>
                  <span v-if="item.quoting == 1"
                    >{{ formatMoneyString(item.minimumMarkup) }}元 /
                    {{ item.quotationUnit }}</span
                  >
                  <span v-else
                    >{{ formatMoneyString(item.minimumMarkup) }}元</span
                  >
                </span>
              </div>
              <div class="projectConsultation">
                <span>参拍成交服务费率:</span>
                <span>{{ item.auctionServiceFeeRate }}%</span>
              </div>
              <div class="projectConsultation">
                <span>拍卖保证金:</span>
                <span>￥{{ formatMoneyString(item.bidBond) }}元</span>
              </div>
              <div class="projectConsultation">
                <span>拍卖服务费费率:</span>
                <span v-if="item.payType == '1'"
                  >￥{{ formatMoneyString(item.biddingServiceFeeRate) }}元</span
                >
                <span v-else>{{ item.biddingServiceFeeRate }}%</span>
              </div>
            </div>
          </div>

          <div class="btnBox">
            <el-button type="success" plain @click="toNoticeDetail(item.id)">
              预览公告详情
            </el-button>
            <!-- <div class="detail pub">拍卖详情</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 支付金额 -->
    <div class="payBox">
      <div class="payTitle">支付金额</div>
      <div
        class="infoServiceFee"
        v-for="(item, index) in priceData"
        :key="index"
      >
        <span>{{ `第${numberToChinese(index + 1)}场拍卖保证金` }}</span>
        <span>￥{{ item }}</span>
      </div>
      <!-- {{ formatMoneyString(item) }} -->
      <!-- <div class="platformLeasingFee">
        <span>平台租赁费</span>
        <span>￥200.00</span>
      </div>

      <div class="bidDeposit">
        <span>竞拍保证金</span>
        <span>￥8000.00</span>
      </div> -->
    </div>

    <div class="sum">
      <div class="payWZ">共需支付金额</div>
      <div class="price1">
        ￥<span class="price">{{ sum }}</span>
      </div>
      <!-- {{ formatMoneyString(this.sum) }} -->
    </div>

    <!-- 按钮 -->
    <div class="btnStyle">
      <el-button type="success" plain @click="onWithdraw()">撤回申请</el-button>
      <el-button type="success" @click="toPay">开始付款</el-button>
    </div>

    <!-- 说明 -->
    <div class="illustrateBox">
      <div class="illustrate">
        <div>
          <el-checkbox v-model="checked1"
            >付款前请先阅读并确认您已接受</el-checkbox
          ><span @click="viewDescript()">《竞拍服务费说明》</span>
        </div>
        <!-- <div>
          <el-checkbox v-model="checked2"
            >付款前请先阅读并确认您已接受</el-checkbox
          ><span>《竞拍业务合同签署规则说明》</span>
        </div> -->
      </div>
    </div>

    <!-- 支付弹窗 -->
    <el-dialog
      title="企业钱包"
      :visible.sync="bmPayVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div class="payPriceBox">
        <div class="priceBox">
          <div class="title">支付金额</div>
          <div class="price">
            ￥<span>{{ formatMoneyString(this.sum) }}</span>
          </div>
        </div>

        <div class="amountAccounting">
          <div class="title">金额核算</div>

          <div class="deposit">
            <div>竞拍保证金</div>
            <div>￥{{ formatMoneyString(this.sum) }}</div>
            <!--  -->
          </div>

          <div class="realPay">
            <div>实际支付</div>
            <div>￥{{ formatMoneyString(this.sum) }}</div>
          </div>
        </div>

        <div class="payPassword">
          <div class="payAccount">
            <div>支付密码</div>
            <div>支付账户：{{ userInfo.manuName }}-企业钱包</div>
          </div>
          <div class="balance">
            可用余额：￥{{ formatMoneyString(withdrawalForm.availableBalance) }}
          </div>
        </div>

        <div class="password">
          <PasswordInput
            ref="passwordInputRef"
            v-model="withdrawalForm.paymentPassword"
            has-gap
            @complete="onPasswordInputComplete"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  confirmAuctionList,
  getTotalDeposit,
  withdrawUpdateStatus,
  auctioneerPayDeposit,
} from "@/api/workSpace";
import { getInfo } from "@/api/index";
import PasswordInput from "@/pages/AccountSettings/components/PasswordInput.vue";
import moment from "moment";
import { mapState } from "vuex";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
import { balance } from "@/api/account";
export default {
  components: {
    PasswordInput,
  },
  data() {
    return {
      bmPayVisible: false,
      checked1: false,
      checked2: true, //目前签署合同不在本系统签署，所以合同规则暂时不展示，先默认设置成true
      loading: false,
      listData: [],
      priceData: [],
      sum: "",
      baseUrl: "/api",
      applicationNumber: "",
      auctionData: {},
      withdrawalForm: {},
    };
  },
  mounted() {
    this.applicationNumber = this.$route.query.auctionCode;
    this.getList();
    this.getTotalDeposit();
  }, 
  watch: {
    bmPayVisible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs["passwordInputRef"].focus();
        });
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    // 查了看竞拍服务费说明
    viewDescript() {
      window.open(
        "/api/file/displayResource?objectName=/paymentInstructions/拍卖平台-平台服务费收费规则.pdf"
      );
    },
    // 获取列表
    getList() {
      this.loading = true;
      confirmAuctionList({ applicationNumber: this.applicationNumber }).then(
        (res) => {
          if (res.code == 200) {
            let data = res.data.map((item) => {
              item.url =
                this.baseUrl +
                "/file/displayResource?objectName=" +
                item.objectNames[0];
              item.person = item.operator + " " + item.operatorNumber;
              item.timeLineData = [];
              item.timeLineData.push({
                content: "拍卖信息发布",
                timestamp: moment(new Date(item.releaseTime)).format(
                  "YYYY-MM-DD HH:mm"
                ),
              });
              if (item.isPreview == "Y") {
                item.timeLineData.push({
                  content: "预展览开始",
                  timestamp: moment(new Date(item.previewStartTime)).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                });
                item.timeLineData.push({
                  content: "预展览结束",
                  timestamp: moment(new Date(item.previewEndTime)).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                });
              }

              item.timeLineData.push({
                content: "拍卖开始",
                timestamp: moment(
                  new Date(item.dateDay + " " + item.auctionStartTime)
                ).format("YYYY-MM-DD HH:mm"),
              });
              item.timeLineData.push({
                content: "拍卖合同签署截止日期",
                timestamp: moment(new Date(item.contractLatestTime)).format(
                  "YYYY-MM-DD HH:mm"
                ),
              });
              return item;
            });
            let nowDate = moment(new Date()).format("YYYY-MM-DD HH:mm");
            // 给时间线添加color
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < data[i].timeLineData.length; j++) {
                if (data[i].timeLineData[j].timestamp < nowDate) {
                  // 如果当前下标的日期小于当前时间，添加color显示为绿色
                  data[i].timeLineData[j].color = "#0DAC65";
                } else {
                  // 如果当前下标日期大于当前日期把当前日期push到当前下标前
                  data[i].timeLineData.splice(j, 0, {
                    content: "当前日期",
                    timestamp: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                    icon: "el-icon-pie-chart",
                    color: "#0DAC65",
                  });
                  break;
                }
              }
            }
            this.listData = data;
            this.loading = false;
          }
        }
      );
    },

    // 撤回申请
    onWithdraw() {
      this.$confirm(
        "您确定要撤回申请吗？撤回申请后信息不可恢复，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        let params = {
          status: 7,
          applicationNumber: this.applicationNumber,
        };
        withdrawUpdateStatus(params).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.$router.push({
              path: "/workSpace",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      });
    },

    // 公告详情
    toNoticeDetail(data) {
      this.$router.push({
        path: "/Notice/announcementDetails",
        query: {
          id: data,
        },
      });
    },

    // 获取保证金
    getTotalDeposit() {
      getTotalDeposit({ applicationNumber: this.applicationNumber }).then(
        (res) => {
          if (res.code == 200) {
            this.priceData = res.data.list;
            this.sum = res.data.sum;
          }
        }
      );
    },

    // 开始付款
    toPay() {
      if (this.checked1 && this.checked2) {
        getInfo().then((res) => {
          if (res.code == 200) {
            if (res.user.walletStatus == "2") {
              this.$message({
                type: "warning",
                message: "您的账户已冻结，请联系管理员操作！",
              });
            } else {
              this.bmPayVisible = true;
              balance().then(({ code, data }) => {
                if (code == 200) this.withdrawalForm = data;
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "请仔细阅读竞拍服务协议并勾选同意所有事项",
        });
      }
    },

    // 密码输入完成回调
    onPasswordInputComplete(val) {
      let pwd = encrypt(val);
      let arr = [];
      this.listData.forEach((item) => {
        arr.push({
          applicationNumber: this.applicationNumber,
          authCode: item.auctionCode,
          amount: item.bidBond,
          pwd,
          releaseTime: item.releaseTime,
        });
      });
      auctioneerPayDeposit(arr).then((res) => {
        if (res.code == 200) {
          this.bmPayVisible = false;
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.$router.push({
            path: "/workSpace",
            query: {
              activeName: "fourth",
            },
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00000099;
}

.el-timeline-item {
  padding-bottom: 5px;
}

/deep/.el-timeline-item__wrapper {
  display: flex;
}

/deep/.el-timeline-item__timestamp.is-top {
  margin-right: 10px;
}

/deep/.el-timeline-item__timestamp {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
/deep/.el-timeline-item__content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.paymentRelease {
  .applicationNo {
    font-size: 16px;
  }
  .title {
    font-weight: 500;
    font-size: 22px;
    color: #000000d9;
    text-align: center;
  }
  .listBox {
    width: 100%;
    box-sizing: border-box;

    .list {
      width: 100%;
      height: 210px;
      padding: 20px;
      // background-color: #ccc;
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid#e0e0e0;
      .img {
        width: 305px;
        height: 170px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listContent {
        width: calc(100% - 305px);
        padding-left: 28px;
        box-sizing: border-box;
        position: relative;
        .tit {
          width: 100%;
          display: flex;
          .public {
            width: 84px;
            height: 24px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
          }
          .wz {
            width: calc(100% - 200px);
            font-weight: 500;
            font-size: 18px;
            color: #000000d9;
            overflow: hidden;
            white-space: nowrap; /* 防止换行 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .contentDetail {
          width: 100%;
          height: 130px;
          margin-top: 18.5px;
          // background-color: #ccc;
          display: flex;

          .timeLine {
            width: 40.2%;
            height: 100%;
            overflow: auto;
            padding: 10px 0 10px 16px;
            background: rgba(216, 216, 216, 0.12);
            box-sizing: border-box;
          }
          .projectConsultation {
            line-height: 26px;
            span {
              &:first-child {
                font-weight: 400;
                font-size: 14px;
                color: #00000066;
                margin-right: 12px;
              }
              &:last-child {
                font-weight: 400;
                font-size: 14px;
                color: #000000d9;
              }
            }
          }
          .auctionDetail {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: 26px;
            .actionCode,
            .actionTime,
            .deposit,
            .projectConsultation {
              margin-right: 40px;
              span {
                &:first-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #00000066;
                  margin-right: 12px;
                }
                &:last-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: #000000d9;
                }
              }
            }
          }
        }

        .btnBox {
          // width: 100%;
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
          .pub {
            width: 100px;
            height: 32px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            margin-left: 20px;
          }
          .hall {
            background: #0dac65;
            border: 1px solid #0dac65;
            color: #ffffff;
          }
          .detail {
            background: #ffffff;
            border: 1px solid #0dac65;
            color: #0dac65;
          }
          .cancel {
            background: #ffffff;
            border: 1px solid #e0e0e0;
            color: #666666;
          }
        }
      }
    }
  }

  .payBox {
    margin-top: 40px;
    padding-bottom: 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    .payTitle {
      font-weight: 600;
      font-size: 32px;
      color: #000000d9;
    }
    .infoServiceFee,
    .platformLeasingFee,
    .bidDeposit {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;
      span {
        font-weight: 400;
        font-size: 18px;
        color: #000000d9;
      }
    }
  }
  .sum {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .payWZ {
      font-weight: 400;
      font-size: 18px;
      color: #000000d9;
    }
    .price1 {
      color: #ff7f00;
      font-size: 18px;
      font-weight: 500;
      margin-left: 40px;
      .price {
        font-size: 32px;
      }
    }
  }
  .btnStyle {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .modify {
      width: 120px;
      height: 40px;
      border: 1px solid #0dac65;
      font-weight: 400;
      font-size: 16px;
      color: #0dac65;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .confirm {
      width: 120px;
      height: 40px;
      background: #0dac65;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      margin-left: 20px;
      cursor: pointer;
    }
  }

  .illustrateBox {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin-top: 28px;
    .illustrate {
      width: fit-content;
      div {
        margin-bottom: 4px;
        span {
          color: #0dac65;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }

  .payPriceBox {
    .priceBox {
      padding-bottom: 33px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 400;
        font-size: 14px;
        color: #000000d9;
        text-align: center;
      }
      .price {
        font-weight: 500;
        font-size: 24px;
        color: #000000d9;
        text-align: center;
        span {
          font-size: 32px;
          margin-left: 8px;
        }
      }
    }
    .amountAccounting {
      margin-top: 41px;
      padding-bottom: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #f0f0f0;
      .title {
        font-weight: 600;
        font-size: 16px;
        color: #000000d9;
        text-align: left;
      }
      .deposit {
        padding-left: 20px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
      }
      .realPay {
        margin-top: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
      }
    }

    .payPassword {
      margin-top: 32px;
      .payAccount {
        // display: flex;
        // justify-content: space-between;
        div {
          &:first-child {
            font-weight: 600;
            font-size: 16px;
            color: #000000d9;
          }
          &:last-child {
            text-align: right;
            margin-top: 10px;
          }
        }
      }
      .balance {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
    .password {
      margin-top: 20px;
    }
  }
}
</style>
