<!-- 发起拍卖申请页面 -->
<template>
  <div class="container">
    <div class="banner">
      <div class="bannerTit">
        <span>拍卖申请</span>
      </div>
    </div>

    <div class="contentBox">
      <!-- 步骤条 -->
      <div class="stepBox">
        <el-steps
          align-center
          :active="active"
          finish-status="success"
          process-status="finish"
        >
          <el-step title="基础信息"></el-step>
          <el-step title="标的信息"></el-step>
          <el-step title="策拍对接"></el-step>
          <el-step title="确认方案"></el-step>
          <el-step title="付款发布"></el-step>
        </el-steps>
      </div>

      <!-- 申请详细信息 -->

      <div class="applicationDetail">
        <!-- 基础信息 -->
        <baseInfo v-show="active == 0" @updateActive="handleActive"></baseInfo>

        <!-- 标的信息 -->
        <subjectMatterInfo
          v-show="active == 1" 
          @updateActive="handleActive"
        ></subjectMatterInfo>

        <!-- 策拍对接 -->
        <planningAndDocking v-if="active == 2" @updateActive="handleActive"></planningAndDocking>

        <!-- 确认方案 -->
        <confirmPlan v-if="active == 3"  @updateActive="handleActive"></confirmPlan>

        <!-- 付款发布 -->
        <paymentRelease v-if="active == 4"></paymentRelease>
      </div>
    </div>
  </div>
</template>

<script>
import baseInfo from "./components/baseInfo.vue";
import subjectMatterInfo from "./components/subjectMatterInfo.vue";
import planningAndDocking from "./components/planningAndDocking.vue";
import confirmPlan from "./components/confirmPlan.vue";
import paymentRelease from "./components/paymentRelease.vue";
export default {
  components: {
    baseInfo,
    subjectMatterInfo,
    planningAndDocking,
    confirmPlan,
    paymentRelease,
  },
  data() {
    return {
      active: 0,
      baseInfoData: {}, //基础信息数据
      auctionCode: "",
    };
  },
  mounted() {
    this.auctionCode = this.$route.query.auctionCode;
    // 不转number类型,当前步骤条没有选中状态
    this.active = Number(this.$route.query.active) || 0;
  },
  methods: {
    handleActive(data) {
      this.active = data;
    },
  },
};
</script>

<style lang="less">
.el-step__title.is-success{
  color: rgba(0,0,0,0.85);
}
.el-step__title.is-wait{
  color: rgba(0,0,0,0.4);
}
.el-step__title.is-finish{
  color: #000000;
  font-weight: 500;
}
.el-step__line{
  background-color: #f0f0f0;
}
.container {
  width: 100%;
  .banner {
    width: 100%;
    height: 200px;
    background: url("../../assets/images/workSpace/banner.png") center/cover
      no-repeat;
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }

  .contentBox {
    width: 1200px;
    margin: 0 auto;
    .stepBox {
      height: 90px;
      margin-top: 40px;
      border-bottom: 1px solid #e0e0e0;
    }

    .applicationDetail {
      padding-top: 40px;
      box-sizing: border-box;
    }
  }
}
</style>
